import React from "react"
import { Link } from "gatsby"
import { FaGooglePlay, FaApple } from "react-icons/fa"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"
import { SectionTitle, SectionBackground } from "reusecore/SectionTitle"

import aboutImage from "assets/images/about/about.svg"
import AboutSectionWrapper from "./about.style"
import {CardTile} from "../../components";

const TileCards = () => {
  return (
    <AboutSectionWrapper id="about">
      <Container>
        <Row>
          {/*<Col className="lg-5 md-6 sm-12">*/}
          {/*  <Image*/}
          {/*    src={aboutImage}*/}
          {/*    className="about-image"*/}
          {/*    alt="cryptik about image"*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col className="md-12">
            <Box className="about-right-content">
              <SectionTitle>
                <SectionBackground>
                  <Heading as="h1">
                     Change The Way You Run Your Business
                  </Heading>
                </SectionBackground>
              </SectionTitle>
          <div className="cards">
              <CardTile
                  title="Accounting truly decentralized"
                  cardDescription="Immutable data means zero financial frauds and decentralized architecture provides security"
                  faIcon="FaProjectDiagram"
                  backgroundColorClassName="card3"
              />
              <CardTile
                  title="Zero knowledge proof"
                  cardDescription="Means you can put trust in DAA. Be sure no one inside company tries fraud data in spreaadsheets."
                  faIcon="FaSuperscript"
                  backgroundColorClassName="card2"
              />
              <CardTile
                title="High transparency for company stakeholders"
                cardDescription="Now every stakeholder will have a good access to the most important financial data"
                faIcon="FaSearchDollar"
                backgroundColorClassName="card2"
              />
              <CardTile
                  title="Fully automated process"
                  cardDescription="Reducing mistakes and improving document workflow"
                  faIcon="FaSyncAlt"
                  backgroundColorClassName="card3"
              />
              <CardTile
                  title="Real time autonomous audits"
                  cardDescription="Which enable to build on top of that another great products, like lending and launchpad platforms for regular businesses"
                  faIcon="FaStopwatch"
                  backgroundColorClassName="card3"
              />
              <CardTile
                  title="Significant costs saving"
                  cardDescription="Due to high automatization, lot of accounting and administrative duties will be covered by Trippl"
                  faIcon="FaPoll"
                  backgroundColorClassName="backgroundColorOptions.card2"
              />
          </div>
              {/*<Box className="about-list">*/}
              {/*  <Text as="span">75% Distributed to Community.</Text>*/}
              {/*  <Text as="span">13% Reserved Funding.</Text>*/}
              {/*  <Text as="span">9% Founders and Team Management.</Text>*/}
              {/*</Box>*/}

              {/*<Box className="about-btn-wrapper">*/}
              {/*  <Link to="#" className="btn btn-fill">*/}
              {/*    {" "}*/}
              {/*    <FaGooglePlay /> Google Play*/}
              {/*  </Link>*/}
              {/*  <Link to="#" className="btn">*/}
              {/*    <FaApple /> Apple Store*/}
              {/*  </Link>*/}
              {/*</Box>*/}
            </Box>
          </Col>
        </Row>
      </Container>
    </AboutSectionWrapper>
  )
}

export default TileCards
